import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { useUser } from '../context/UserContext';
import './style.css';
import {Link, useNavigate} from "react-router-dom";

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { login } = useUser();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Create a new FormData instance
        const formData = new FormData();
        formData.append('email', email);
        formData.append('password', password);

        try {
            const response = await axios.post('https://generatepdfeasy.eu/login/login.php',formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.data.status === 'success') {
                // Assuming user data is returned, update the context
                login({ email, threadId: response.data.threadId });
                navigate('/profile'); // Redirect to profile page
            } else {
                alert('Login failed: ' + response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <section className="login-section-container">
            <form onSubmit={handleSubmit}>
                <h2>Вхід</h2>
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button type="submit">Увійти</button>
                <p>У вас ще немає облікового запису? <Link to={'/register'}><span className="make-registration-text">Зареєструватись</span></Link></p>
            </form>
        </section>
    );
}

export default Login;
