import React, { useState } from 'react';
import axios from 'axios';
import "./style.css";
import {Link} from "react-router-dom";
import ConnectTelegram from "./components/ConnectTelegram";

function Register() {
    const [userRegistered, setUserRegistered] = useState(false);
    const [userId, setUserId] = useState("");
    const [data, setData] = useState({
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        phone: '+380',
        address: '',
        preferredContact: '',
        gender: 'male'
    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'phone') {
            if (!value.startsWith('+380')) {
                return; // Prevent changes before the +380 prefix
            }
        }

        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (data.phone.length !== 13 || isNaN(data.phone)) {
            alert("Номер телефону повинен складатися з 11 цифр, наприклад, '+380961112233'");
            return;
        }

        // Create a new FormData instance
        const formData = new FormData();
        formData.append('email', email);
        formData.append('password', password);
        formData.append('firstName', data.firstName);
        formData.append('lastName', data.lastName);
        formData.append('dateOfBirth', data.dateOfBirth);
        formData.append('phone', data.phone);
        formData.append('address', data.address);
        formData.append('preferredContact', data.preferredContact);
        formData.append('gender', data.gender);

        try {
            const response = await axios.post('https://generatepdfeasy.eu/login/register.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.data.status === 'success') {
                setUserId(response.data.user);
                setUserRegistered(true);
            } else {
                if (response.data.message === 'Email already exists.'){
                    alert('Вже існує такий обліковий запис');
                } else {
                    alert('Registration failed: ' + response.data.message);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <section className="login-section-container">
            <form>
                <h2>Register</h2>
            {!userRegistered ?
                <>
                    <input
                        type="email"
                        placeholder="Електронна пошта"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <input
                        type="password"
                        placeholder="Пароль"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <input
                        type="text"
                        name="firstName"
                        placeholder="Ім'я"
                        value={data.firstName}
                        onChange={handleChange}
                        className="contact-input"
                        required
                    />
                    <input
                        type="text"
                        name="lastName"
                        placeholder="Прізвище"
                        value={data.lastName}
                        onChange={handleChange}
                        className="contact-input"
                        required
                    />
                    <input
                        type="date"
                        name="dateOfBirth"
                        value={data.dateOfBirth}
                        onChange={handleChange}
                        className="contact-input"
                        required
                    />
                    <input
                        type="tel"
                        name="phone"
                        placeholder="Номер телефону"
                        value={data.phone}
                        onChange={handleChange}
                        className="contact-input"
                        required
                    />
                    <input
                        type="text"
                        name="address"
                        placeholder="Адреса"
                        value={data.address}
                        onChange={handleChange}
                        className="contact-input"
                        required
                    />
                    <div className="gender-selection">
                        <label>
                            <input
                                type="radio"
                                name="gender"
                                value="male"
                                checked={data.gender === 'male'}
                                onChange={handleChange}
                                className="contact-radio"
                            />
                            Чоловік
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="gender"
                                value="female"
                                checked={data.gender === 'female'}
                                onChange={handleChange}
                                className="contact-radio"
                            />
                            Жінка
                        </label>
                    </div>
                    <select
                        name="preferredContact"
                        value={data.preferredContact}
                        onChange={handleChange}
                        className="contact-select"
                        required
                    >
                        <option value="">Бажаний спосіб зв'язку</option>
                        <option value="TELEGRAM">TELEGRAM</option>
                        <option value="PHONE">ТЕЛЕФОН</option>
                        <option value="VIBER">VIBER</option>
                        <option value="WHATSAPP">WHATSAPP</option>
                    </select>
                    <button onClick={handleSubmit}>Зареєструватись</button>
                    <p>Вже є обліковий запис? <Link to={'/login'}><span className="make-registration-text">Увійти</span></Link></p>
                </>
                 :
                <ConnectTelegram userId={userId} />
            }
            </form>
        </section>
    );
}

export default Register;
