import React, {useEffect, useRef, useState} from 'react';
import axios from 'axios'; // Import Axios
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import './Dashboard.css';
import {useUser} from "../context/UserContext";

function Dashboard() {
    const fileInputRef = useRef(null);
    const { user, userAssistants } = useUser();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [assistants, setAssistants] = useState([]);
    const [activeTab, setActiveTab] = useState(null);
    const [editableAssistant, setEditableAssistant] = useState(null);
    const [receivedFiles, setReceivedFiles] = useState([]);
    const [save, setSave] = useState(true);

    useEffect(() => {
        const fetchAssistants = async () => {
            const formData = new FormData();
            formData.append('action', 'getAssistantsList');
            userAssistants.forEach((assistant, index) => {
                formData.append(`assistants[${index}]`, assistant);
            });

            try {
                const response = await axios.post('https://generatepdfeasy.eu/index.php', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (response.status === 200) {
                    const assistants = response.data.map(assistant => ({
                        ...assistant,
                        fileSearchEnabled: assistant.tools.some(tool => tool.type === 'file_search'), // Initialize checkbox
                        vector_store_id: assistant.tool_resources?.file_search?.vector_store_ids[0], // Initialize vector store ID
                    }));

                    const updatedAssistants = await Promise.all(
                        assistants.map(async (item) => {
                            if (item.vector_store_id) {
                                const formDataVector = new FormData();
                                formDataVector.append('action', 'getFilesByVectorStore');
                                formDataVector.append('vectorStoreId', item.vector_store_id);

                                try {
                                    const response = await axios.post('https://generatepdfeasy.eu/index.php', formDataVector, {
                                        headers: {
                                            'Content-Type': 'multipart/form-data',
                                        },
                                    });

                                    const fileArray = response.data.data;
                                    return {
                                        ...item,
                                        files: fileArray || []
                                    };
                                } catch (error) {
                                    console.error('Error fetching files:', error);
                                    return { ...item, files: [] };
                                }
                            }
                            return { ...item, files: [] };
                        })
                    );

                    console.log("updatedAssistants", updatedAssistants);
                    setAssistants(updatedAssistants); // Use updated assistants
                    setActiveTab(updatedAssistants[0].id);
                    setEditableAssistant(updatedAssistants[0]); // Set first assistant as editable
                }
            } catch (error) {
                console.error('Error fetching assistants:', error);
            }
        };

        fetchAssistants();
    }, []);

    const handleTabChange = (tabId) => {
        setActiveTab(tabId);
        const selectedAssistant = assistants.find(assistant => assistant.id === tabId);
        setEditableAssistant(selectedAssistant);
    };

    const handleOpenAssistant = () => {
        if (editableAssistant.id === "asst_m8ARLWyEAa6nABh4WGQsNcgg"){
            navigate("/assistant", { state: {
                threadId: user.threadId,
                voice: selectedVoice
                }});
            return;
        }
        if (editableAssistant.id === "asst_NdiwJYK9zzSirOfEnnv2rwni"){
            navigate("/avatar", { state: user.threadId });
            return;
        }
    };

    const handleLaunch = async () => {
        // if (save) {
            handleOpenAssistant();
            return;
        // }

        if (receivedFiles.length === 0) {
            console.log('No files to upload.');
            handleSaveChanges();
            return;
        }

        const formData = new FormData();
        formData.append('action', 'uploadFiles');
        formData.append('vectorStoreId', editableAssistant.vector_store_id);

        // Append each file from receivedFiles to the FormData
        receivedFiles.forEach((file, index) => {
            formData.append(file.fileName, file.file)
            // formData.append(`file_${index}`, file);
        });

        try {
            const response = await axios.post('https://generatepdfeasy.eu/index.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                if (response.data.success){
                    console.log('Files uploaded successfully:', response.data);
                    setReceivedFiles([]);
                    handleSaveChanges();
                }
                // Handle the response as needed, e.g., update state or navigate to a new page
            } else {
                console.error('Error uploading files:', response.statusText);
            }
        } catch (error) {
            console.error('Error uploading files:', error);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setSave(false);
        setEditableAssistant((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleFileSearchToggle = () => {
        setSave(false);
        setEditableAssistant((prevData) => ({
            ...prevData,
            fileSearchEnabled: !prevData.fileSearchEnabled,
        }));
    };


    const handleFileUpload = (event) => {
        setSave(false);
        const files = Array.from(event.target.files); // Get the list of files

        setReceivedFiles((prevReceivedFiles) => [
            ...prevReceivedFiles,
            ...files.map(file => ({
                file: file,
                fileName: file.name,
            }))
        ]);// Store the files in receivedFiles

        const fileNames = files.map(file => file.name); // Extract file names

        // Update the editableAssistant state with the new file names
        setEditableAssistant((prevData) => ({
            ...prevData,
            files: [...(prevData.files || []), ...fileNames] // Append new file names
        }));
    };

    const handleFileRemove = (fileName) => {
        setSave(false);
        // Remove the file from receivedFiles by filtering out the file with the matching name
        setReceivedFiles(receivedFiles.filter(file => file.fileName !== fileName));

        // Update the editableAssistant state by filtering out the file name from the files array
        setEditableAssistant((prevData) => ({
            ...prevData,
            files: prevData.files.filter(name => name !== fileName),
        }));
    };

    const modelOptions = {
        "gpt-4o": "Потужний",
        "gpt-4o-mini": "Швидкий",
        "gpt-3.5": "Економний",
    };

    const handleSaveChanges = async () => {
        const updatedAssistant = {
            name: editableAssistant.name || "",
            description: editableAssistant.description || "",
            instructions: editableAssistant.instructions || "",
            model: editableAssistant.model || "",
            // metadata: typeof editableAssistant.metadata === 'object' && !Array.isArray(editableAssistant.metadata)
            //     ? editableAssistant.metadata
            //     : {}, // Ensure metadata is an object, default to an empty object
            tools: editableAssistant.tools || [], // Default to an empty array if tools are missing
            tool_resources: editableAssistant.tool_resources || {}, // Default to an empty object if tool_resources are missing
            top_p: editableAssistant.top_p !== undefined ? editableAssistant.top_p : 1, // Default to 1 if top_p is missing
            temperature: editableAssistant.temperature !== undefined ? editableAssistant.temperature : 1, // Default to 1 if temperature is missing
            // response_format: editableAssistant.response_format?.type || "" // Default to an empty string if response_format or type is missing
        };

        try {
            const formData = new FormData();
            formData.append('action', 'updateAssistant');
            formData.append('id', editableAssistant.id);
            formData.append('data', JSON.stringify(updatedAssistant));

            const response = await axios.post('https://generatepdfeasy.eu/index.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                setSave(true);
                // Update the assistant in the state with the saved data
                // setAssistants((prevAssistants) => prevAssistants.map(assistant =>
                //     assistant.id === editableAssistant.id ? editableAssistant : assistant
                // ));
                // alert('Assistant updated successfully!');
            }
        } catch (error) {
            console.error('Error saving assistant:', error);
        }
    };

    useEffect(() => {
        console.log("editable assistant: ", editableAssistant);
    }, [editableAssistant]);

    const [selectedVoice, setSelectedVoice] = useState("Alloy");

    const voices = ["Alloy", "Echo", "Fable", "Onyx", "Nova", "Shimmer"];

    const handleSelectChange = (event) => {
        setSelectedVoice(event.target.value);
    };

    const renderTabContent = (assistantData) => {
        return (
            <div className="tab-content">
                <label htmlFor="name" className="neon-text">Назва асистента</label>
                <input
                    type="text"
                    name="name"
                    value={assistantData.name || ''}
                    onChange={handleInputChange}
                    className="input-field"
                />
                {/*<textarea*/}
                {/*    name="instructions"*/}
                {/*    value={assistantData.instructions || ''}*/}
                {/*    onChange={handleInputChange}*/}
                {/*    className="textarea-field"*/}
                {/*/>*/}
                <div className="block-container">
                    <div className="block">
                        <label htmlFor="model" className="neon-text">Потужність моделі</label>
                        <select
                            name="model"
                            value={assistantData.model || ''}
                            onChange={handleInputChange}
                            className="select-field"
                        >
                            {Object.entries(modelOptions).map(([modelValue, displayName]) => (
                                <option key={modelValue} value={modelValue}>
                                    {displayName}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="block">
                        <label htmlFor="voiceDropdown" className="neon-text">Голос озвучення</label>
                        <select
                            name="voice"
                            value={selectedVoice}
                            onChange={handleSelectChange}
                            className="select-field"
                        >
                            {voices.map((option, index) => (
                                <option key={index} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="toggle-file-search">
                    <label className="label-file-search">
                        <input
                            type="checkbox"
                            checked={assistantData.fileSearchEnabled || false}
                            onChange={handleFileSearchToggle}
                        />
                        Пошук по файлах
                    </label>
                </div>
                {assistantData.fileSearchEnabled && assistantData.files && (
                    <>
                        <div className="files-list">
                            {assistantData.files.map((fileName, index) => (
                                <div className="file-item" key={index}>
                                    <span className="file-name">{fileName}</span>
                                    <button className="file-remove" onClick={() => handleFileRemove(fileName)}>
                                    🗑
                                    </button>
                                </div>
                            ))}
                        </div>
                        <div className="file-upload-container">
                            <input
                                type="file"
                                accept=".pdf,.docx,.txt"
                                onChange={(e) => handleFileUpload(e)}
                                className="file-input"
                                ref={fileInputRef}
                            />
                            <button className="add-file-button" onClick={() => fileInputRef.current.click()}>
                                + Додати
                            </button>
                        </div>
                    </>
                )}
                <button className="launch-button" onClick={handleLaunch} disabled={loading}>
                    {!save ? <span>Зберегти змінни</span> : <span>Запуск<i className="launch-icon">🚀</i></span>}
                </button>
            </div>
        );
    };

    return (
        <div className="dashboard">
            <div className="tabs">
                {assistants.map((assistant, index) => (
                    <button
                        key={index}
                        className={`tab neon-text ${activeTab === assistant.id ? 'active' : ''}`}
                        onClick={() => handleTabChange(assistant.id)}
                    >
                        {assistant.name}
                    </button>
                ))}
            </div>
            <div className="content">
                {editableAssistant && renderTabContent(editableAssistant)}
            </div>
        </div>
    );
}

export default Dashboard;
