import React, {useEffect, useRef, useState} from "react";
import "./style.css";
import {useLocation} from "react-router-dom";

export default function AzureAvatar() {
    // const [startSessionDisable, setStartSessionDisable] = useState(false);
    // const [isStartSession, setIsStartSession] = useState(false);
    // const [webrtcStatus, setWebrtcStatus] = useState('');
    const location = useLocation();
    const threadId = location.state || {};
    // start detect voice if true
    const [isCanTalk, setIsCanTalk] = useState(false);
    const [audioSrc, setAudioSrc] = useState(null);

    const [mode, setMode] = useState('default');

    // const [threadId, setThreadId] = useState('');

    // const [isStopSpeaking, setIsStopSpeaking] = useState(false);

    // const [avatarSynthesizer, setAvatarSynthesizer] = useState(null);
    // const cogSvcRegion = "northeurope";
    // const cogSvcSubKey = "0cfe87f4679042cdb9e3c6fadf7bb907";
    // let ttsVoice = "uk-UA-PolinaNeural";
    // const talkingAvatarCharacter = "lisa";
    // const talkingAvatarStyle = "casual-sitting";

    // const remoteVideoRef = useRef(null);
    // const videoLabelRef = useRef(null);
    // const overlayAreaRef = useRef(null);
    // const canvasRef = useRef(null);
    // const stopSessionRef = useRef(null);
    // const speakRef = useRef(null);
    // const stopSpeakingRef = useRef(null);
    // const startSessionRef = useRef(null);
    // const spokenTextRef = useRef(null);

    const [isRecording, setIsRecording] = useState(false);
    const audioRef = useRef(null);
    const [isNoiseDetected, setIsNoiseDetected] = useState(false);
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    const streamRef = useRef(null);
    const analyserRef = useRef(null);
    const microphoneRef = useRef(null);
    const lastNoiseDetectedRef = useRef(Date.now());
    const silenceTimeoutRef = useRef(null);
    const silenceBuffer = useRef(2000); // Buffer period for detecting silence (20 seconds)
    const noiseThreshold = useRef(20);  // Adjust as needed for your environment
    const [isPlayed, setIsPlayed] = useState(false);

    useEffect(() => {
        return () => {
            stopRecording();
            if (streamRef.current) {
                streamRef.current.getTracks().forEach(track => track.stop());
            }
            if (silenceTimeoutRef.current) {
                clearTimeout(silenceTimeoutRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (isPlayed) {

        }
    }, [isPlayed]);
    const startRecording = async () => {
        try {
            // setMode('default');
            console.log("Requesting microphone access...");
            setIsNoiseDetected(false);

            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            console.log("Microphone access granted");
            streamRef.current = stream;

            mediaRecorderRef.current = new MediaRecorder(stream);
            mediaRecorderRef.current.ondataavailable = event => {
                console.log("Data available from recorder");
                audioChunksRef.current.push(event.data);
            };

            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            analyserRef.current = audioContext.createAnalyser();
            microphoneRef.current = audioContext.createMediaStreamSource(stream);
            microphoneRef.current.connect(analyserRef.current);

            console.log("Starting noise detection...");
            detectNoise();
        } catch (error) {
            console.error("Error accessing microphone:", error);
        }
    };

    const detectNoise = () => {
        const analyser = analyserRef.current;
        const dataArray = new Uint8Array(analyser.fftSize);

        const checkNoise = () => {
            analyser.getByteTimeDomainData(dataArray);

            let sum = 0;
            for (let i = 0; i < dataArray.length; i++) {
                sum += (dataArray[i] - 128) * (dataArray[i] - 128);
            }
            const rms = Math.sqrt(sum / dataArray.length);

            const currentTime = Date.now();

            if (rms > noiseThreshold.current) {
                // Noise detected
                lastNoiseDetectedRef.current = currentTime;
                if (!isNoiseDetected) {
                    console.log("Noise detected");
                    setMode('listening');
                    setIsNoiseDetected(true);
                    startMediaRecording();
                }
                // Reset the silence timeout
                if (silenceTimeoutRef.current) {
                    clearTimeout(silenceTimeoutRef.current);
                }
                silenceTimeoutRef.current = setTimeout(() => {
                    console.log("No noise detected for 2 seconds, stopping recording...");
                    stopRecording();
                    setIsNoiseDetected(false);
                }, silenceBuffer.current); // Use buffer period
            } else if (isNoiseDetected) {
                // Noise was detected previously, now it's silent
                if (silenceTimeoutRef.current) {
                    clearTimeout(silenceTimeoutRef.current);
                }
                silenceTimeoutRef.current = setTimeout(() => {
                    console.log("No noise detected for 2 seconds, stopping recording...");
                    stopRecording();
                    setIsNoiseDetected(false);
                }, silenceBuffer.current); // Use buffer period
            }

            // Continue detecting noise
            requestAnimationFrame(checkNoise);
        };

        checkNoise();
    };

    const startMediaRecording = () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'recording') {
            console.log("Starting MediaRecorder...");
            mediaRecorderRef.current.start();
            setIsRecording(true);
        } else {
            console.warn("MediaRecorder is already recording.");
        }
    };

    const stopRecording = () => {
        console.log("Stopping recording...");
        setIsRecording(false);
        setIsCanTalk(false);

        // Stop the MediaRecorder
        if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
            mediaRecorderRef.current.stop();
            mediaRecorderRef.current.onstop = () => {
                if (audioChunksRef.current.length > 0) {
                    const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
                    saveAudioFile(audioBlob);
                    audioChunksRef.current = [];
                }
            };
        }

        // Stop all tracks in the media stream
        if (streamRef.current) {
            streamRef.current.getTracks().forEach(track => {
                track.stop();
            });
            streamRef.current = null;
        }

        // Clear the silence timeout if it exists
        if (silenceTimeoutRef.current) {
            clearTimeout(silenceTimeoutRef.current);
            silenceTimeoutRef.current = null;
        }

        // Optionally reset the MediaRecorder reference
        mediaRecorderRef.current = null;
    };

    // old version
    // const [recording, setRecording] = useState(false);
    // const mediaRecorderRef = useRef(null);
    // const audioChunksRef = useRef([]);
    //
    // const startRecording = async () => {
    //     setRecording(true);
    //     audioChunksRef.current = [];
    //
    //     try {
    //         const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    //         mediaRecorderRef.current = new MediaRecorder(stream);
    //
    //         mediaRecorderRef.current.ondataavailable = (event) => {
    //             if (event.data.size > 0) {
    //                 audioChunksRef.current.push(event.data);
    //             }
    //         };
    //
    //         mediaRecorderRef.current.onstop = () => {
    //             const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
    //
    //             // Send the audio file to the server
    //             saveAudioFile(audioBlob);
    //         };
    //
    //         mediaRecorderRef.current.start();
    //     } catch (err) {
    //         console.error('Error accessing microphone', err);
    //     }
    // };
    //
    // const stopRecording = () => {
    //     setRecording(false);
    //     mediaRecorderRef.current.stop();
    // };

    const saveAudioFile = async (audioBlob) => {
        // Prepare the audio file for upload
        const formData = new FormData();
        formData.append('audio', audioBlob, 'recording.webm');
        formData.append('action', "getAnswer");
        formData.append('threadId', threadId);
        setMode('thinking');

        // Send the audio file to the PHP backend
        await fetch('https://generatepdfeasy.eu/index.php', {
            method: 'POST',
            body: formData,
        })
            .then(response => response)
            .then(data => {
                if (data.error) {
                    console.error('Error:', data.error);
                } else {
                    // console.log('Transcription:', data.choices[0].message.content);
                    // console.log("Data file: ", data.file);
                    fetchAudio();
                    // speak(data.text);
                }
            })
            .catch(error => console.error('Error:', error));
    };

    const fetchAudio = async () => {
        try {
            const response = await fetch('https://generatepdfeasy.eu/speech.txt'); // Replace with the correct URL to the .txt file

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const base64String = await response.text();  // Get the Base64 string from the .txt file

            // Decode Base64 string into binary data
            const binaryString = atob(base64String);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }

            // Create a Blob from the binary data
            const blob = new Blob([bytes], { type: 'audio/mpeg' });

            // Create a URL for the Blob
            const url = URL.createObjectURL(blob);
            setMode('answering');
            setAudioSrc(url);

            // Cleanup the URL object when the component unmounts
            return () => URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error fetching audio:', error);
        }
    };

    useEffect(() => {
        if (audioSrc && audioRef.current) {
            audioRef.current.play();
        }
    }, [audioSrc]);
    // const toggleRecording = () => {
    //     if (recording) {
    //         stopRecording();
    //     } else {
    //         startRecording();
    //     }
    // };

    // function setupWebRTC (iceServerUrl, iceServerUsername, iceServerCredential) {
    //     // Create WebRTC peer connection
    //     const pc = new RTCPeerConnection({
    //         iceServers: [
    //             {
    //                 urls: [iceServerUrl],
    //                 username: iceServerUsername,
    //                 credential: iceServerCredential,
    //             },
    //         ],
    //     });
    //
    //     // setPeerConnection(pc);
    //     pc.ontrack = (event) => {
    //         const remoteVideoDiv = remoteVideoRef.current;
    //
    //         // Clean up existing video/audio elements
    //         Array.from(remoteVideoDiv.childNodes).forEach((child) => {
    //             if (child.localName === event.track.kind) {
    //                 remoteVideoDiv.removeChild(child);
    //             }
    //         });
    //
    //         // Create a new media player element (video or audio)
    //         const mediaPlayer = document.createElement(event.track.kind);
    //         mediaPlayer.id = event.track.kind;
    //         mediaPlayer.srcObject = event.streams[0];
    //         mediaPlayer.autoplay = true;
    //         remoteVideoDiv.appendChild(mediaPlayer);
    //
    //         // Hide video label and show overlay area
    //         // videoLabelRef.current.hidden = true;
    //         overlayAreaRef.current.hidden = false;
    //
    //         if (event.track.kind === 'video') {
    //             mediaPlayer.playsInline = true;
    //
    //             const canvas = canvasRef.current;
    //             canvas.hidden = true;
    //
    //             mediaPlayer.addEventListener('play', () => {
    //                 remoteVideoDiv.style.width = `${mediaPlayer.videoWidth / 2}px`;
    //             });
    //         } else {
    //             // Mute the audio player to ensure autoplay
    //             mediaPlayer.muted = true;
    //         }
    //     };
    //
    //     pc.oniceconnectionstatechange = () => {
    //         setWebrtcStatus(pc.iceConnectionState);
    //
    //         if (pc.iceConnectionState === 'connected') {
    //             // stopSessionRef.current.disabled = false;
    //             // speakRef.current.disabled = false;
    //         }
    //
    //         if (pc.iceConnectionState === 'disconnected' || pc.iceConnectionState === 'failed') {
    //             // speakRef.current.disabled = true;
    //             // stopSpeakingRef.current.disabled = true;
    //             // stopSessionRef.current.disabled = true;
    //             // startSessionRef.current.disabled = false;
    //         }
    //     };
    //
    //     // Create a data channel and listen for messages
    //     const c = pc.createDataChannel('eventChannel');
    //     pc.addEventListener('datachannel', (event) => {
    //         const dataChannel = event.channel;
    //         dataChannel.onmessage = (e) => {
    //             console.log(`[${new Date().toISOString()}] WebRTC event received: ${e.data}`);
    //         };
    //     });
    //
    //     // Offer to receive 1 audio, and 1 video track
    //     pc.addTransceiver('video', { direction: 'sendrecv' });
    //     pc.addTransceiver('audio', { direction: 'sendrecv' });
    //
    //     // Start avatar and establish WebRTC connection
    //     avatarSynthesizer.startAvatarAsync(pc).then((r) => {
    //         console.log("avatarSynthesizer.startAvatarAsync(pc) response: ", r);
    //         if (r.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted) {
    //             console.log(`[${new Date().toISOString()}] Avatar started. Result ID: ${r.resultId}`);
    //         } else {
    //             console.log(`[${new Date().toISOString()}] Unable to start avatar. Result ID: ${r.resultId}`);
    //             handleAvatarError(r);
    //         }
    //     }).catch((error) => {
    //         console.log(`[${new Date().toISOString()}] Avatar failed to start. Error: ${error}`);
    //         handleAvatarError();
    //     });
    //
    //     return () => {
    //         pc.close();
    //     };
    // }

    // const handleAvatarError = (r) => {
    //     if (r?.reason === SpeechSDK.ResultReason.Canceled) {
    //         const cancellationDetails = SpeechSDK.CancellationDetails.fromResult(r);
    //         if (cancellationDetails.reason === SpeechSDK.CancellationReason.Error) {
    //             console.log(cancellationDetails.errorDetails);
    //         }
    //         setWebrtcStatus(cancellationDetails.errorDetails);
    //     }
    //     // startSessionRef.current.disabled = false;
    // };

    // const handleAvatarStartSession = async (cogSvcRegion, cogSvcSubKey) => {
    //     avatarSynthesizer.avatarEventReceived = (s, e) => {
    //         let offsetMessage = ", offset from session start: " + e.offset / 10000 + "ms.";
    //         if (e.offset === 0) {
    //             offsetMessage = ""
    //         }
    //         console.log("[" + (new Date()).toISOString() + "] Event received: " + e.description + offsetMessage);
    //     }
    //
    //     setStartSessionDisable(true);
    //
    //     try {
    //         const url = `https://${cogSvcRegion}.tts.speech.microsoft.com/cognitiveservices/avatar/relay/token/v1`;
    //         const response = axios.get(url, {
    //             headers: {
    //                 'Ocp-Apim-Subscription-Key': cogSvcSubKey,
    //             },
    //         });
    //
    //         response.then(response => {
    //             console.log("responseData: ", response.data);
    //             const responseData = response.data;
    //             const iceServerUrl = responseData.Urls[0];
    //             const iceServerUsername = responseData.Username;
    //             const iceServerCredential = responseData.Password;
    //
    //             setupWebRTC(iceServerUrl, iceServerUsername, iceServerCredential);
    //         });
    //     } catch (e) {
    //         console.error('Error fetching token and setting up WebRTC:', e);
    //     }
    // }

    // const startSession = async () => {
    //
    //     const speechSynthesisConfig = SpeechSDK.SpeechConfig.fromSubscription(cogSvcSubKey, cogSvcRegion)
    //
    //     speechSynthesisConfig.endpointId = "";
    //
    //     const videoFormat = new SpeechSDK.AvatarVideoFormat();
    //     // const windowHeight = window.innerHeight;
    //     // let videoCropTopLeftX = 600;
    //     // let videoCropBottomRightX = 1320;
    //     videoFormat.setCropRange(new SpeechSDK.Coordinate(600, 0), new SpeechSDK.Coordinate(1320, 1080));
    //
    //     const avatarConfig = new SpeechSDK.AvatarConfig(talkingAvatarCharacter, talkingAvatarStyle, videoFormat);
    //
    //     avatarConfig.customized = false;
    //     avatarConfig.backgroundColor = "#FFFFFF";
    //     const newAvatarSynthesizer = new SpeechSDK.AvatarSynthesizer(speechSynthesisConfig, avatarConfig);
    //     setAvatarSynthesizer(newAvatarSynthesizer);
    //     console.log("newAvatarSynthesizer is set: ", newAvatarSynthesizer);
    //
    //     // create thread and store it in variable
    //     createThread();
    // };

    // useEffect(() => {
    //     // Only run handleAvatarStartSession when avatarSynthesizer is available
    //     if (avatarSynthesizer) {
    //         // Create an inline async function to handle delay and session start
    //         (async () => {
    //             await new Promise(resolve => setTimeout(resolve, 1000)); // 1-second delay
    //             await handleAvatarStartSession(cogSvcRegion, cogSvcSubKey);
    //         })();
    //     }
    // }, [avatarSynthesizer, cogSvcRegion, cogSvcSubKey]);

    // function createThread() {
    //     const formData = new FormData();
    //     formData.append('action', "createThread");
    //
    //     // Send the audio file to the PHP backend
    //     fetch('https://generatepdfeasy.eu/index.php', {
    //         method: 'POST',
    //         body: formData,
    //     })
    //         .then(response => response.json())
    //         .then(data => {
    //             if (data.error) {
    //                 console.error('Error:', data.error);
    //             } else {
    //                 // console.log('Transcription:', data.choices[0].message.content);
    //                 console.log("Data thread: ", data);
    //                 setThreadId(data);
    //             }
    //         })
    //         .catch(error => console.error('Error:', error));
    // }
    // Do HTML encoding on given text
    // function htmlEncode(text) {
    //     const entityMap = {
    //         '&': '&amp;',
    //         '<': '&lt;',
    //         '>': '&gt;',
    //         '"': '&quot;',
    //         "'": '&#39;',
    //         '/': '&#x2F;'
    //     };
    //
    //     return String(text).replace(/[&<>"'\/]/g, (match) => entityMap[match]);
    // }

    // const handleSpeak = async (spokenSsml, spokenText) => {
    //     // speakRef.current.disabled = true;
    //
    //     console.log("handleSpeak avatarSynthesizer: ", avatarSynthesizer);
    //     try {
    //         const result = await avatarSynthesizer.speakSsmlAsync(spokenSsml);
    //
    //         if (result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted) {
    //             console.log(`[${new Date().toISOString()}] Speech synthesized to speaker for text [ ${spokenText} ]. Result ID: ${result.resultId}`);
    //             setIsCanTalk(true);
    //         } else {
    //             console.log(`[${new Date().toISOString()}] Unable to speak text. Result ID: ${result.resultId}`);
    //             if (result.reason === SpeechSDK.ResultReason.Canceled) {
    //                 const cancellationDetails = SpeechSDK.CancellationDetails.fromResult(result);
    //                 console.log(cancellationDetails.reason);
    //                 if (cancellationDetails.reason === SpeechSDK.CancellationReason.Error) {
    //                     console.log(cancellationDetails.errorDetails);
    //                 }
    //             }
    //         }
    //     } catch (error) {
    //         console.error(error);
    //     } finally {
    //         // speakRef.current.disabled = false;
    //     }
    // };

    // const speak = (spokenText) => {
    //     console.log("Speak is clicked");
    //     // speakRef.current.disabled = true;
    //     // stopSpeakingRef.current.disabled = false;
    //     document.getElementById('audio').muted = false
    //
    //     // let spokenText = "Привіт, як справи?";
    //     let personalVoiceSpeakerProfileID = "";
    //     let spokenSsml = `<speak version='1.0' xmlns='http://www.w3.org/2001/10/synthesis' xmlns:mstts='http://www.w3.org/2001/mstts' xml:lang='en-US'><voice name='${ttsVoice}'><mstts:ttsembedding speakerProfileId='${personalVoiceSpeakerProfileID}'><mstts:leadingsilence-exact value='0'/>${htmlEncode(spokenText)}</mstts:ttsembedding></voice></speak>`;
    //     console.log("[" + (new Date()).toISOString() + "] Speak request sent.");
    //     console.log("speak avatarSynthesizer: ", avatarSynthesizer);
    //     handleSpeak(spokenSsml, spokenText);
    // };

    // const stopSpeaking = () => {
    //     setIsStopSpeaking(true);
    //     avatarSynthesizer.stopSpeakingAsync()
    //         .then(() => {
    //             console.log(`[${new Date().toISOString()}] Stop speaking request sent.`);
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         });
    // };

    // const stopSession = () => {
    //     // speakRef.current.disabled = true;
    //     // stopSessionRef.current.disabled = true;
    //     // stopSessionRef.current.disabled = true;
    //
    //     avatarSynthesizer.close();
    // };

    // useEffect(() => {
    //     console.log("webrtcStatus: ", webrtcStatus);
    //     if (webrtcStatus === 'connected') {
    //         setIsStartSession(true);
    //         setIsCanTalk(true);
    //         return;
    //     }
    //     setIsStartSession(false);
    // }, [webrtcStatus]);

    useEffect(() => {
        if (isCanTalk){
            startRecording();
        }
    }, [isCanTalk]);

    useEffect(() => {

    }, []);

    const hadleEndAudio = () => {
        setIsPlayed(false);
        setMode('default');
        startRecording();
    };

    // start Recording when page is loaded
    useEffect(() => {
        startRecording();
    }, []);

    useEffect(() => {
        console.log("Mode: ", mode);
        if (mode === 'default'){
            startRecording();
        }
    }, [mode]);
    const stopAudio = () => {
        if (audioRef.current) {
            audioRef.current.pause(); // Pause the audio
            audioRef.current.currentTime = 0; // Reset the audio to the beginning
            setIsPlayed(false); // Reset any state related to playback
            setMode('default');
        }
    };

    return (
        <div className="circle-container">
            {/*{isStartSession &&*/}
            {/*    // Old version*/}
            {/*    <div style={{ textAlign: 'center', padding: '20px' }}>*/}
            {/*        <h2>Speech Recorder</h2>*/}
            {/*        <button onClick={toggleRecording}>*/}
            {/*            {recording ? 'Stop Recording' : 'Start Recording'}*/}
            {/*        </button>*/}
            {/*    </div>*/}
            {/*    */}
            {/*    // new version*/}
            {/*    <div>*/}
            {/*        {!isRecording && (*/}
            {/*            <button onClick={startRecording}>Start</button>*/}
            {/*        )}*/}
            {/*        {isRecording && (*/}
            {/*            <button onClick={stopRecording}>Stop Recording</button>*/}
            {/*        )}*/}
            {/*    </div>*/}
            {audioSrc && (
                <audio controls={false} ref={audioRef} onPlay={() => setIsPlayed(true)} onEnded={() => hadleEndAudio()} src={audioSrc}>
                    {/*<source src="https://generatepdfeasy.eu/speech.acc" type="audio/aac" />*/}
                    Your browser does not support the audio element.
                </audio>
            )}
            <div className={`circle ${mode}`}>
                {
                    mode === 'default' && <span>Сплю</span>
                }
                {
                    mode === 'listening' && <span>Слухаю</span>
                }
                {
                    mode === 'thinking' && <span>Думаю</span>
                }
                {
                    mode === 'answering' && <span>Відповідаю</span>
                }
            </div>
            {audioSrc && isPlayed && <button className="button-stop" onClick={stopAudio}>Stop Audio</button>}
            {/*}*/}
            {/*<h1>Talking Avatar Service Demo</h1>*/}
            {/*<div>*/}
                {/*<h2 style={{ backgroundColor: 'white', width: '300px' }}>Avatar Control Panel</h2>*/}
                {/*<label style={{ fontSize: 'medium' }} htmlFor="spokenText">Spoken Text:</label><br />*/}
                {/*<textarea id="spokenText" style={{ height: '40px' }} ref={spokenTextRef}>Hello world!</textarea><br />*/}
                {/*{!isStartSession && <div className="circle-button-container">*/}
                {/*    <button className="circle-button" id="startSession" onClick={startSession} ref={startSessionRef} disabled={startSessionDisable}>Start Session</button>*/}
                {/*</div>}*/}
                {/*<button id="speak" onClick={speak} ref={speakRef}>Speak</button>*/}
                {/*<button id="stopSpeaking" onClick={stopSpeaking} ref={stopSpeakingRef} disabled={isStopSpeaking}>Stop Speaking</button>*/}
                {/*<button id="stopSession" onClick={stopSession} ref={stopSessionRef} disabled>Stop Session</button>*/}
                {/*<br />*/}
                    {/*<h2 id="videoLabel" style={{ backgroundColor: 'white', width: '300px' }} ref={videoLabelRef}>Avatar Video</h2>*/}
                {/*<div id="videoContainer" style={{ position: 'relative', width: '960px' }}>*/}
                {/*    <div id="overlayArea" style={{ position: 'absolute' }} ref={overlayAreaRef} hidden>*/}
                {/*        /!* Add your text or image controls here *!/*/}
                {/*        <p id="overlayText" style={{ fontSize: 'large' }}></p>*/}
                {/*        /!* <img id="overlayImage" src="your-image-source.png" alt="Overlay Image" /> *!/*/}
                {/*    </div>*/}
                {/*    <div id="remoteVideo" ref={remoteVideoRef}></div>*/}
                {/*    <canvas id="canvas" width="100vw" height="80vh" style={{ backgroundColor: 'transparent' }} ref={canvasRef} hidden></canvas>*/}
                {/*    <canvas id="tmpCanvas" width="100vw" height="80vh" hidden></canvas>*/}
                {/*</div>*/}
            {/*</div>*/}
        </div>
    );
}
