import HeroSection from "./components/HeroSection";
import HowItWorks from "./components/HowItWorks";
import WhyNeedIt from "./components/WhyNeedIt";
import Contacts from "./components/Contacts";
import Prices from "./components/Prices";

export default function HomePage() {
    return (
        <>
            <HeroSection />
            {/*<HowItWorks />*/}
            {/*<WhyNeedIt />*/}
            {/*<Contacts />*/}
            {/*<Prices />*/}
        </>
    );
}
