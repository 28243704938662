import React, {useEffect, useRef, useState} from "react";
import "./style.css";
import {useLocation} from "react-router-dom";
import shortSound from "../assets/sounds/short_sound.mp3"

export default function Assistant() {
    const location = useLocation();
    const {threadId, voice} = location.state || {};

    // start detect voice if true
    const [isCanTalk, setIsCanTalk] = useState(false);
    const [audioSrc, setAudioSrc] = useState(null);

    const [mode, setMode] = useState('default');

    const [isRecording, setIsRecording] = useState(false);
    const audioRef = useRef(null);
    const [audioContext, setAudioContext] = useState(null);
    const [isNoiseDetected, setIsNoiseDetected] = useState(false);
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    const streamRef = useRef(null);
    const analyserRef = useRef(null);
    const microphoneRef = useRef(null);
    const lastNoiseDetectedRef = useRef(Date.now());
    const silenceTimeoutRef = useRef(null);
    const silenceBuffer = useRef(2000); // Buffer period for detecting silence (2 seconds)
    const noiseThreshold = useRef(10);  // Adjust as needed for your environment
    const [isPlayed, setIsPlayed] = useState(false);
    const [isPermissionGranted, setIsPermissionGranted] = useState(false);

    const requestMicrophonePermission = async () => {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            console.error('Your browser does not support microphone access.');
            return;
        }

        try {
            // Request microphone access on user click or action
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            streamRef.current = stream;
            setIsPermissionGranted(true);
            // console.log('Microphone access granted!', stream);
        } catch (error) {
            // Specific handling for different errors
            if (error.name === 'NotAllowedError') {
                console.error('Microphone access denied by the user.');
            } else if (error.name === 'NotFoundError') {
                console.error('No microphone found on this device.');
            } else if (error.name === 'NotReadableError') {
                console.error('Microphone is being used by another application.');
            } else if (error.name === 'OverconstrainedError') {
                console.error('The specified constraints cannot be satisfied by any available device.');
            } else {
                console.error('An unknown error occurred while trying to access the microphone.');
            }
            setIsPermissionGranted(false);
        }
    };

    useEffect(() => {
        requestMicrophonePermission();

        return () => {
            // Clean up microphone stream when component unmounts
            if (streamRef.current) {
                streamRef.current.getTracks().forEach((track) => track.stop());
            }
        };
    }, []);

    useEffect(() => {
        if (mode === 'sleeping'){
            console.log("Mode record started")
            startRecording();
        }
    }, [mode]);

    useEffect(() => {
        return () => {
            stopRecording();
            if (streamRef.current) {
                streamRef.current.getTracks().forEach(track => track.stop());
            }
            if (silenceTimeoutRef.current) {
                clearTimeout(silenceTimeoutRef.current);
            }
        };
    }, []);

    const startRecording = async () => {
        try {
            // setMode('default');
            setIsNoiseDetected(false);
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

            // if (streamRef.current && isPermissionGranted) {
                mediaRecorderRef.current = new MediaRecorder(stream);
                mediaRecorderRef.current.ondataavailable = event => {
                    console.log("Data available from recorder");
                    audioChunksRef.current.push(event.data);
                };

                // const audioContext = new (window.AudioContext || window.webkitAudioContext)();
                analyserRef.current = audioContext.createAnalyser();
                microphoneRef.current = audioContext.createMediaStreamSource(stream);
                microphoneRef.current.connect(analyserRef.current);

                console.log("Starting noise detection...");
                detectNoise();
            // } else {
            //     console.log("Microphone permission is required to start recording.");
            // }
        } catch (error) {
            console.error("Error accessing microphone:", error);
        }
    };

    const detectNoise = () => {
        const analyser = analyserRef.current;
        const dataArray = new Uint8Array(analyser.fftSize);

        const checkNoise = () => {
            analyser.getByteTimeDomainData(dataArray);

            let sum = 0;
            for (let i = 0; i < dataArray.length; i++) {
                sum += (dataArray[i] - 128) * (dataArray[i] - 128);
            }
            const rms = Math.sqrt(sum / dataArray.length);

            // Convert RMS to dB
            const referenceValue = 128; // Adjust this reference value as needed
            const dB = 20 * Math.log10(rms / referenceValue);

            const currentTime = Date.now();
            const timeInSeconds = (currentTime / 1000).toFixed(3);

            if (rms > noiseThreshold.current) {
                // Noise detected
                lastNoiseDetectedRef.current = currentTime;
                if (!isNoiseDetected) {
                    console.log(`Noise detected at ${timeInSeconds} seconds with dB level: ${dB.toFixed(2)} dB`);
                    setMode('listening');
                    setIsNoiseDetected(true);
                    startMediaRecording();
                }
                // Reset the silence timeout
                if (silenceTimeoutRef.current) {
                    clearTimeout(silenceTimeoutRef.current);
                }
                silenceTimeoutRef.current = setTimeout(() => {
                    console.log("No noise detected for 2 seconds, stopping recording...");
                    stopRecording();
                    setIsNoiseDetected(false);
                }, silenceBuffer.current); // Use buffer period
            } else if (isNoiseDetected) {
                // Noise was detected previously, now it's silent
                if (silenceTimeoutRef.current) {
                    clearTimeout(silenceTimeoutRef.current);
                }
                silenceTimeoutRef.current = setTimeout(() => {
                    console.log("No noise detected for 2 seconds, stopping recording...");
                    stopRecording();
                    setIsNoiseDetected(false);
                }, silenceBuffer.current); // Use buffer period
            }

            // Continue detecting noise
            requestAnimationFrame(checkNoise);
        };

        checkNoise();
    };

    const startMediaRecording = () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'recording') {
            console.log("Starting MediaRecorder...");
            mediaRecorderRef.current.start();
            setIsRecording(true);
        } else {
            console.warn("MediaRecorder is already recording.");
        }
    };

    const downloadAudio = (audioBlob) => {
        // Create a URL for the Blob
        const url = URL.createObjectURL(audioBlob);

        // Create a temporary link element
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;

        // Set the download attribute with a filename
        a.download = 'recording.wav';  // You can change the extension to .wav or other if needed

        // Append the link to the document body
        document.body.appendChild(a);

        // Programmatically trigger the click event on the link to start download
        a.click();

        // Remove the link after download
        document.body.removeChild(a);

        // Release the object URL to free up memory
        URL.revokeObjectURL(url);
    };

    const stopRecording = () => {
        console.log("Stopping recording...");
        setIsRecording(false);
        setIsCanTalk(false);

        // Stop the MediaRecorder
        if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
            mediaRecorderRef.current.stop();
            mediaRecorderRef.current.onstop = () => {
                if (audioChunksRef.current.length > 0) {
                    const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
                    // downloadAudio(audioBlob);
                    saveAudioFile(audioBlob);
                    audioChunksRef.current = [];
                }
            };
        }

        // Stop all tracks in the media stream
        if (streamRef.current) {
            streamRef.current.getTracks().forEach(track => {
                track.stop();
            });
            streamRef.current = null;
        }

        // Clear the silence timeout if it exists
        if (silenceTimeoutRef.current) {
            clearTimeout(silenceTimeoutRef.current);
            silenceTimeoutRef.current = null;
        }

        // Optionally reset the MediaRecorder reference
        mediaRecorderRef.current = null;
    };
    // const callCount = useRef(1);
    const saveAudioFile = async (audioBlob) => {
        // Prepare the audio file for upload
        const formData = new FormData();

        // Fake talk mode
        // formData.append('action', 'fakeTalk');
        // formData.append('answer', `${callCount.current}`);
        // console.log("fakeTalkCount", callCount);
        // callCount.current += 1;
        // Fake talk mode

        formData.append('audio', audioBlob, 'recording.wav');
        formData.append('action', "getAssistant");
        formData.append('threadId', threadId);
        formData.append('voice', voice);
        setMode('thinking');

        // Send the audio file to the PHP backend
        await fetch('https://generatepdfeasy.eu/index.php', {
        // await fetch('http://localhost:8000/index.php', {
            method: 'POST',
            body: formData,
        })
            .then(response => {
                const contentType = response.headers.get('content-type');
                if (contentType && contentType.includes('application/json')) {
                    // Parse the JSON response
                    return response.json();
                } else {
                    // If the response is not JSON, call fetchAudio immediately
                    fetchAudio();
                    return null; // Return null to signal that there's no JSON to process
                }
            })
            .then(data => {
                console.log("Data: ", data);
                if (data && data.description && data.startDate) {
                    // If valid data is received, call fetchAudio and then handleAddReminder with a delay
                    fetchAudio();
                    setTimeout(() => {
                        handleAddReminder(data.description, data.startDate);
                    }, 12000);
                }
            })
            .catch(error => console.error('Error:', error));

            // .then(response => response.json())
            // .then(data => {
            //     if (data.error) {
            //         console.error('Error:', data.error);
            //     } else {
            //         console.log("data: ", data);
            //         // console.log('Transcription:', data.choices[0].message.content);
            //         // console.log("Data file: ", data.file);
            //         // if functtion create calendar event works
            //         if (data.description && data.startDate) {
            //             fetchAudio();
            //             setTimeout(() => {
            //                 handleAddReminder(data.description , data.startDate);
            //             }, 10000);
            //             return;
            //         }
            //         fetchAudio();
            //         // speak(data.text);
            //     }
            // })
            // .catch(error => console.error('Error:', error));
    };

    const fetchAudio = async () => {
        console.log("FetchAudio");
        try {
            const response = await fetch(`https://generatepdfeasy.eu/audio/${threadId}.txt`); // Replace with the correct URL to the .txt file

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const base64String = await response.text();  // Get the Base64 string from the .txt file

            // Decode Base64 string into binary data
            const binaryString = atob(base64String);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }

            // Create a Blob from the binary data
            const blob = new Blob([bytes], { type: 'audio/mpeg' });

            // Create a URL for the Blob
            const url = URL.createObjectURL(blob);
            setMode('answering');
            setAudioSrc(url);

            // Cleanup the URL object when the component unmounts
            return () => URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error fetching audio:', error);
        }
    };

    useEffect(() => {
        if (audioSrc && audioRef.current) {
            audioRef.current.play();
        }
    }, [audioSrc]);

    useEffect(() => {
        if (isCanTalk){
            startRecording();
        }
    }, [isCanTalk]);

    const hadleEndAudio = () => {
        setIsPlayed(false);
        setMode('sleeping');
        startRecording();
    };

    const stopAudio = () => {
        if (audioRef.current) {
            audioRef.current.pause(); // Pause the audio
            audioRef.current.currentTime = 0; // Reset the audio to the beginning
            setIsPlayed(false); // Reset any state related to playback
            setMode('sleeping');
        }
    };

    const handlePlayAudio = () => {
        const audioFilePath = '/assets/audio.wav'; // Path to your audio file in the public/assets folder
        setAudioSrc(audioFilePath);
    };
    const handleClick = () =>{
        if (mode === 'default') {
            setMode('sleeping');
            setAudioSrc(shortSound);
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            setAudioContext(audioContext);
            // setAudioSrc('../assets/sounds/short_sound.mp3');
        }
    }

    // const handleClick = () => {
    //     if (mode === "default") {
    //         // setMode("default");
    //         requestMicrophonePermission();
    //     }
    // }
    return (
        <div className="circle-container">
            {audioSrc && (
                <audio controls={false} ref={audioRef} onPlay={() => setIsPlayed(true)} onEnded={() => hadleEndAudio()} src={audioSrc}>
                    {/*<source src="https://generatepdfeasy.eu/speech.acc" type="audio/aac" />*/}
                    Your browser does not support the audio element.
                </audio>
            )}
            <div className={`circle ${mode}`} onClick={handleClick}
                 // onClick={handleClick}
            >
                {
                    mode === 'default' && <span>Натисніть, щоб розпочати</span>
                }
                {
                    mode === 'sleeping' && <span>Сплю</span>
                }
                {
                    mode === 'listening' && <span>Слухаю</span>
                }
                {
                    mode === 'thinking' && <span>Думаю</span>
                }
                {
                    mode === 'answering' && <span>Відповідаю</span>
                }
            </div>
            {audioSrc && isPlayed && <button className="button-stop" onClick={stopAudio}>Stop Audio</button>}
        </div>
    );
}

const handleAddReminder = (description, startDate) => {
    const title = 'Запис у клініку Нісходовського';
    const location = 'Саперне поле 3, Київ, Україна';

    // Parse startDate (assuming format YYYY-MM-DD HH:MM)
    const parsedStartDate = new Date(startDate.replace(' ', 'T'));

    // Add 1.5 hours to startDate to calculate endDate
    const endDate = new Date(parsedStartDate.getTime() + 1.5 * 60 * 60 * 1000);

    // Convert to Google Calendar format (YYYYMMDDTHHMMSSZ)
    const startDateFormatted = parsedStartDate.toISOString().replace(/-|:|\.\d{3}/g, '');
    const endDateFormatted = endDate.toISOString().replace(/-|:|\.\d{3}/g, '');

    // Create Google Calendar URL
    const calendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
        title
    )}&dates=${startDateFormatted}/${endDateFormatted}&details=${encodeURIComponent(
        description
    )}&location=${encodeURIComponent(location)}&sf=true`;

    // window.location.href = "intent://com.google.android.calendar/#Intent;scheme=geo;package=com.google.android.calendar;end";
    // Redirect user to the Google Calendar URL
    window.location.href = calendarUrl;
};
