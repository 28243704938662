import React from 'react';
import { Link } from 'react-router-dom';
import HeroImage from '../../assets/image/robot_hero_image.jpg';
import '../styles.css'; // Import the CSS file

function HeroSection() {

    return (
        <section className="hero-section">
            <div className="background-container">
                <h1>Ласкаво просимо в нову еру помічників зі ШІ</h1>
                <button className="login-button">
                    <Link to="/login" className="login-button-text">ВХІД</Link>
                </button>
            </div>
            {/*<div className="hero-content">*/}
            {/*    <img*/}
            {/*        src={HeroImage} // Placeholder image URL, replace with actual image URL*/}
            {/*        alt="Virtual Avatar Assistant"*/}
            {/*        className="hero-image"*/}
            {/*    />*/}
            {/*    <div className="hero-text">*/}
            {/*        <h1>Welcome to the new era of AI Assistant</h1>*/}
            {/*        <div className="hero-buttons">*/}
            {/*            <Link to="/login" className="button">Login</Link>*/}
            {/*            <Link to="/register" className="button">Sign Up</Link>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </section>
    );
}

export default HeroSection;
