import React from 'react';
import { useUser } from '../context/UserContext';
import { useNavigate } from "react-router-dom";
import './style.css';
import Dashboard from "../components/Dashboard";
import Carousel from "../components/Carousel";
function Profile() {
    const { user } = useUser();
    const navigate = useNavigate();

    if (!user) {
        navigate('/login');
        // return <div className="profile-container">
        //     <button className="styled-button" onClick={() => navigate('/login')}>Зайти в систему</button>
        // </div>;
    }

    return (
        <div className="profile-container">
            <Carousel />
            {/*<Dashboard />*/}
        </div>
    );
}

export default Profile;
