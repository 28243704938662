import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from "./login/Login";
import Register from "./login/Register";
import {UserProvider} from "./context/UserContext";
import Profile from "./login/Profile";
import HomePage from "./homePage";
import Assistant from "./components/Assistant";
import AzureAvatar from "./components/AzureAvatar";
import VoiceRecorder from "./components/test/VoiceRecorder";

function App() {
  return (
    <UserProvider>
        <Router>
            <Routes>
                <Route index path="/" element={<HomePage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/assistant" element={<Assistant />} />
                <Route path="/avatar" element={<AzureAvatar />} />
                <Route path="/test" element={<VoiceRecorder />} />
            </Routes>
        </Router>
    </UserProvider>
  );
}

export default App;
