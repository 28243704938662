import React, {createContext, useState, useContext, useEffect} from 'react';

const UserContext = createContext();

export function UserProvider({ children }) {
    const [user, setUser] = useState(null);

    const login = (userData) => {
        setUser(userData);
    };

    const logout = () => {
        setUser(null);
    };

    useEffect(() => {
        console.log("User data: ", user);
    }, [user]);

    const userAssistants = ['asst_m8ARLWyEAa6nABh4WGQsNcgg', 'asst_NdiwJYK9zzSirOfEnnv2rwni'];
    return (
        <UserContext.Provider value={{ user, login, logout, userAssistants }}>
            {children}
        </UserContext.Provider>
    );
}

export function useUser() {
    return useContext(UserContext);
}
