import React, { useState, useEffect } from 'react';
import axios from 'axios';

export default function ConnectTelegram({userId}) {
    const [telegramToken, setTelegramToken] = useState('');

    useEffect(() => {
        // Generate a unique token when the user registers
        const generateToken = async () => {
            const formData = new FormData()
            formData.append("action", "generateTelegramToken");
            formData.append("userId", userId);
            try {
                const response = await axios.post('https://generatepdfeasy.eu/index.php', formData);
                setTelegramToken(response.data.token); // Token returned from backend
            } catch (error) {
                console.error('Error generating token:', error);
            }
        };

        generateToken();
    }, []);

    const handleTelegramLink = () => {
        // Redirect user to the Telegram bot with their token
        const telegramBotUrl = `https://t.me/VuzollBot?start=${telegramToken}`;
        window.open(telegramBotUrl, '_blank');
    };

    return (
        <div>
            <button onClick={handleTelegramLink}>Прив'язати Телеграм Аккаунт</button>
        </div>
    );
}
