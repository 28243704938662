import React, { useState, useEffect, useRef } from 'react';

const VoiceRecorderWithDetection = () => {
    const [isRecording, setIsRecording] = useState(false);
    const [audioSrc, setAudioSrc] = useState(null);
    const [silenceDelay, setSilenceDelay] = useState(2000); // время задержки для остановки записи после тишины
    const [noiseThreshold, setNoiseThreshold] = useState(0.1); // порог чувствительности
    const [isMicrophoneEnabled, setIsMicrophoneEnabled] = useState(true);

    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    const silenceTimeoutRef = useRef(null);
    const audioContextRef = useRef(null);
    const analyserRef = useRef(null);
    const sourceRef = useRef(null);

    useEffect(() => {
        if (!isMicrophoneEnabled) return;

        const startMicrophone = async () => {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            audioContextRef.current = audioContext;

            const analyser = audioContext.createAnalyser();
            analyser.fftSize = 2048;
            analyserRef.current = analyser;

            const source = audioContext.createMediaStreamSource(stream);
            source.connect(analyser);
            sourceRef.current = source;

            mediaRecorderRef.current = new MediaRecorder(stream);

            // Начинаем проверку уровня громкости для детекции голоса
            detectVoice();
        };

        startMicrophone();

        return () => {
            disableMicrophone();
        };
    }, [isMicrophoneEnabled]);

    const detectVoice = () => {
        const analyser = analyserRef.current;
        const bufferLength = analyser.fftSize;
        const dataArray = new Uint8Array(bufferLength);

        const checkVolume = () => {
            analyser.getByteFrequencyData(dataArray);
            const averageVolume = dataArray.reduce((sum, value) => sum + value, 0) / bufferLength;

            if (averageVolume > noiseThreshold * 255) {
                console.log('Голос обнаружен, начинается запись.');
                startRecording();
            } else if (isRecording) {
                if (silenceTimeoutRef.current) clearTimeout(silenceTimeoutRef.current);

                silenceTimeoutRef.current = setTimeout(() => {
                    console.log('Тишина обнаружена, останавливаем запись.');
                    stopRecording();
                }, silenceDelay);
            }

            if (isMicrophoneEnabled) {
                requestAnimationFrame(checkVolume);
            }
        };

        checkVolume();
    };

    const startRecording = () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'recording') {
            mediaRecorderRef.current.start();
            setIsRecording(true);
            console.log('Начата запись аудио.');
            mediaRecorderRef.current.ondataavailable = (event) => {
                audioChunksRef.current.push(event.data);
            };

            mediaRecorderRef.current.onstop = () => {
                const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
                const audioURL = URL.createObjectURL(audioBlob);
                setAudioSrc(audioURL);
                audioChunksRef.current = []; // очищаем для новой записи
                setIsRecording(false);
                console.log('Запись остановлена и сохранена.');
            };
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
            console.log('Запись остановлена.');
        }
    };

    const disableMicrophone = () => {
        setIsMicrophoneEnabled(false);

        if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
            stopRecording();
        }

        if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
            audioContextRef.current.close().then(() => {
                console.log('Микрофон отключен и AudioContext закрыт.');
            }).catch((error) => {
                console.error('Ошибка при закрытии AudioContext:', error);
            });
        }
    };

    // Воспроизведение аудио
    useEffect(() => {
        if (audioSrc) {
            const audio = new Audio(audioSrc);
            audio.play();
            console.log('Проигрывание записанного аудио.');
            audio.onended = () => {
                setAudioSrc(null);
                setIsMicrophoneEnabled(true); // Возвращаем микрофон в режим прослушивания
                console.log('Аудио проиграно, микрофон снова активен.');
            };
        }
    }, [audioSrc]);

    // Отключение микрофона при уходе со страницы или обновлении
    useEffect(() => {
        const handleBeforeUnload = () => {
            disableMicrophone();
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    return (
        <div>
            <h1>Voice Recorder with Voice Detection</h1>
            {isMicrophoneEnabled && isRecording && <p>Запись...</p>}
            {!isRecording && <p>Ожидание голоса...</p>}

            <audio src={audioSrc} controls={!!audioSrc} />

            <div>
                <label>
                    Время задержки после конца разговора (мс):
                    <input
                        type="number"
                        value={silenceDelay}
                        onChange={(e) => setSilenceDelay(Number(e.target.value))}
                    />
                </label>
            </div>

            <div>
                <label>
                    Чувствительность микрофона (0.0 - 1.0):
                    <input
                        type="number"
                        value={noiseThreshold}
                        onChange={(e) => setNoiseThreshold(Number(e.target.value))}
                        step="0.01"
                        min="0.0"
                        max="1.0"
                    />
                </label>
            </div>

            <button onClick={disableMicrophone}>Отключить микрофон</button>
        </div>
    );
};

export default VoiceRecorderWithDetection;
