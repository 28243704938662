import React, {useEffect, useState} from 'react';
import './Carousel.css';
import ModernTooth from "../assets/image/modern_tooth-removebg-preview.png";
import {useNavigate} from "react-router-dom";
import {useUser} from "../context/UserContext";

const Carousel = () => {
    const navigate = useNavigate();
    const { user, userAssistants } = useUser();
    const voices = ["Андрій", "Олександр", "Тарас", "Дмитро", "Олена", "Марія"];
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [selectedVoice, setSelectedVoice] = useState(voices[0]);

    const handleSelect = (voice) => {
        setSelectedVoice(voice);
    };

    const divs = [
        { id: "asst_m8ARLWyEAa6nABh4WGQsNcgg", title: 'Адміністратор', description: "Этот віртуальний асистент стоматологічної клініки \"Niskhodovskiy Clinic\" допоможе вам отримати всю необхідну інформацію про послуги клініки та ціни. Асистент надає деталі щодо лікування, професійної чистки, реставрації зубів, імплантації та інших процедур. Він також допоможе організувати запис на зручний час, враховуючи робочі години клініки та вже заплановані візити." },
        { id: "asst_NdiwJYK9zzSirOfEnnv2rwni", title: 'Стоматолог', description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." },
        // { id: 3, content: 'Div 3: Content goes here' },
        // { id: 4, content: 'Div 4: Content goes here' },
        // Add more divs as needed
    ];



    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handlePrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const handleNext = () => {
        if (currentIndex < divs.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handleClick = (index) => {
        const isWideScreen = windowWidth > 1024;
        const shouldGoNext = (isWideScreen && index === 1) || (!isWideScreen && index === 0);

        if (shouldGoNext) {
            handleNext();
        } else {
            handlePrev();
        }
    };

    const handleOpenAssistant = () => {
        const currentAssistant = divs[currentIndex];
        if (currentAssistant.id === "asst_m8ARLWyEAa6nABh4WGQsNcgg"){
            navigate("/assistant", { state: {
                    threadId: user.threadId,
                    voice: selectedVoice
                }});
            return;
        }
        if (currentAssistant.id === "asst_NdiwJYK9zzSirOfEnnv2rwni"){
            navigate("/avatar", { state: user.threadId });
            return;
        }
    };

    return (
        <div className="carousel-main-container">
            <div className="carousel-container">
                <div className="carousel">
                    {divs.map((div, index) => (
                        <div
                            key={div.id}
                            onClick={() => handleClick(index)}
                            className={`carousel-item ${index === currentIndex ? 'active' : ''} ${
                                index === currentIndex - 1 ? 'left' : ''
                            } ${index === currentIndex + 1 ? 'right' : ''}`}
                        >
                            <h1>{div.title}</h1>
                            <img src={ModernTooth} alt={div.title} />
                            <p className="description">{div.description}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className="bottom-navigation-container">
                <div className="voice-container">
                    <h2 className="neon-text">Озвучення</h2>
                    <div className="voices-grid">
                        {voices.map((voice, index) => (
                            <div
                                key={index}
                                className={`voice-item ${selectedVoice === voice  ? 'selected' : ''}`}
                                onClick={() => handleSelect(voice)}
                            >
                                {voice}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="launch-container ">
                    <div className="anim-border">
                        <button className="launch-button" onClick={handleOpenAssistant}>
                            Запуск
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Carousel;
